import React, { ReactElement } from 'react';
import { animated, useSpring } from '@react-spring/web';

import * as S from './styled';

type IModalProps = {
    children: ReactElement;
    isShowing: boolean;
    onClose(): void;
};

interface FadeProps {
    children?: React.ReactElement;
    in: boolean;
    onEnter?: () => Record<string, unknown>;
    onExited?: () => Record<string, unknown>;
}

const Fade = React.forwardRef<HTMLDivElement, FadeProps>((props, ref) => {
    // eslint-disable-next-line react/prop-types
    const { in: open, children, onEnter, onExited, ...other } = props;
    const style = useSpring({
        config: { tension: 250 },
        from: { opacity: 0 },
        to: { opacity: open ? 1 : 0 },
        onStart: () => {
            if (open && onEnter) {
                onEnter();
            }
        },
        onRest: () => {
            if (!open && onExited) {
                onExited();
            }
        },
    });

    return (
        <animated.div ref={ref} style={style} {...other}>
            {children}
        </animated.div>
    );
});

const Modal = ({ children, isShowing, onClose }: IModalProps): JSX.Element => (
    <S.Container
        open={isShowing}
        onClose={onClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        className="modal-container"
    >
        <Fade in={isShowing}>
            <S.Body>{children}</S.Body>
        </Fade>
    </S.Container>
);

export { Modal };
